import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavbarSec.css";

const NavbarSec = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation();
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const [navbarBgColor, setNavbarBgColor] = useState("none");

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position > 200) {
        setNavbarBgColor("block"); // Change background color when scrolled
      } else {
        setNavbarBgColor("none"); // Reset background color when back to top
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className="nav-main-nav-top test_position"
      style={{ display: navbarBgColor }}>
      <div className="nav-main-nav">
        <nav className="Navbar_main">
          <div
            style={{
              padding: "0.5rem 0.2rem",
              border: "1px solid white",
            }}>
            <Link
              to="/"
              style={{
                padding: "0.4rem 0",
                fontSize: "2rem",
                color: "white",
                fontFamily: "Merriweather Sans",
                border: "1px solid",
                padding: "0.3rem 0.5rem",
              }}
              // style={{
              //   color: "white",
              //   fontSize: "2rem",
              //   fontWeight: "600",
              // }}
            >
              {/* <Link to="/" style={{ color:"white",height: "90px", padding: "0.4rem 0" }}> */}
              {/* <img
                      // src={images.logo}
                      alt=""
                      style={{ height: "100%", width: "100%" }}
                    /> */}
              Tatva Overseas
            </Link>
          </div>
          <div className="navbar_links_main">
            <div className="">
              <Link
                to="/"
                className="link_decor_nav nav_link_h3  nav_link_h3_about"
                style={{
                  color:
                    location.pathname === "/"
                      ? "var(--color-white)"
                      : "var(--color-black)",
                }}>
                Home
              </Link>
            </div>
            <div className="">
              <Link
                to="/About"
                className="link_decor_nav nav_link_h3 nav_link_h3_about"
                style={{
                  color:
                    location.pathname === "/About"
                      ? "var(--color-white)"
                      : "var(--color-black)",
                }}>
                About
              </Link>
            </div>
            <div
              className="dropdown"
              onMouseEnter={() => setShowProductsDropdown(true)}
              onMouseLeave={() => setShowProductsDropdown(false)}>
              <Link className="link_decor_nav nav_link_h3">Products</Link>
              {showProductsDropdown && (
                <div className="dropdown-content">
                  <Link to="/FruitsVeges">Fruits & Vegetables</Link>
                  <hr />
                  <Link to="/Spices">Spices</Link> <hr />
                  <Link to="/Groceries">Grocery Groceries</Link> <hr/>
                  <Link to="/OilSeeds">Oil Seeds</Link>
                </div>
              )}
            </div>

            <div className="">
              <Link
                to="/Contact"
                className="link_decor_nav nav_link_h3  nav_link_h3_about"
                style={{
                  color:
                    location.pathname === "/Contact"
                      ? "var(--color-white)"
                      : "var(--color-black)",
                }}>
                Contact
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <>
        {toggleMenu && (
          <div className="mobile-nav-main-edit">
            {/* <div className="mobile_nav_main_edit"> */}
            <ul className="mobile-nav-links">
              <li>
                <Link
                  to={"/"}
                  onClick={() => setToggleMenu(false)}
                  className="mobile-nav-link">
                  Home
                </Link>
              </li>
              <div className="dropdown">
                <Link to="/About" className="mobile-nav-link">
                  About
                  <i className="fa fa-caret-down"></i>
                </Link>
              </div>
              <div
                className="dropdown"
                onMouseEnter={() => setShowProductsDropdown(true)}
                onMouseLeave={() => setShowProductsDropdown(false)}>
                <Link className="link_decor_nav nav_link_h3">Products</Link>
                {showProductsDropdown && (
                  <div className="dropdown-content">
                    <Link to="/FruitsVeges">Fruits & Vegetables</Link>
                    <hr />
                    <Link to="/Spices">Spices</Link> <hr />
                    <Link to="/Groceries">Grocery Groceries</Link> <hr/>
                    <Link to="/OilSeeds">Oil Seeds</Link>
                  </div>
                )}
              </div>

              <li>
                <Link
                  to={"/Contact"}
                  // onClick={() => setToggleMenu(false)}
                  className="mobile-nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        )}
      </>
    </div>
  );
};

export default NavbarSec;
