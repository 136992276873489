import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import images from "../../constants/images";
import "./Groceries.css";

const Groceries = () => {
  const photos = [
    { id: 1, name: "Toor Dal (Pigeon Pea split)", url: images.ToorDal },
    { id: 2, name: "Urad Dal (Black Gram split)", url: images.UradDal },
    { id: 3, name: "Masoor Dal (Red Lentil )", url: images.MasoorDal },
    {
      id: 4,
      name: "Chickpea (Garbanzo / Chhola-Chhana)",
      url: images.Chickpea,
    },
    { id: 5, name: "Split chickpeas (Chana Dal)", url: images.Chanadal },
    { id: 6, name: "Moth Dal", url: images.MothDal },
    { id: 7, name: "Kidney Beans (Rajma)", url: images.Rajma },
    { id: 7, name: "Peas", url: images.dryPeas },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPhotos, setFilteredPhotos] = useState(photos);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = photos.filter((photo) =>
      photo.name.toLowerCase().includes(query)
    );
    setFilteredPhotos(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | Groceries</title>
      </Helmet>
      <div style={{ paddingBottom: "0rem" }}>
        <Navbar />
      </div>

      <div className="pro_head_main">Groceries</div>
      <div className="pro_main_para">
        <p
          className="about_content_text"
          style={{
            margin: "0 auto",
            maxWidth: "1140px",
            marginTop: "3rem",
            marginBottom: "1rem",
            letterSpacing: "0.5px",
            wordSpacing: "0.5px",
          }}>
          At Tatva Overseas, we take pride in sourcing and exporting a wide
          range of high-quality grocery products to meet the diverse needs of
          our global clients. Our collection includes staple pulses such as{" "}
          <strong>Toor Dal (Pigeon Pea)</strong>,{" "}
          <strong>Urad Dal (Black Gram)</strong>, and{" "}
          <strong>Masoor Dal (Red Lentil)</strong>, which are essential
          components in various cuisines worldwide. Additionally, we offer{" "}
          <strong>Chickpea (Garbanzo)</strong>,{" "}
          <strong>Chana Dal (Split Chickpeas)</strong>, and{" "}
          <strong>Moth Dal</strong>, all harvested from trusted sources to
          ensure the highest standards of quality and nutritional value. These
          products are carefully processed to maintain their freshness, texture,
          and flavor, making them ideal for both retail and bulk supply.
        </p>
        <p
          className="about_content_text"
          style={{
            margin: "0 auto",
            maxWidth: "1140px",
            marginBottom: "2rem",
            letterSpacing: "0.5px",
            wordSpacing: "0.5px",
          }}>
          Our commitment to providing premium grocery products extends to a
          variety of beans like <strong>Rajma (Kidney Beans)</strong>, which are
          not only rich in protein but also a staple in many households across
          the globe. We work closely with local farmers to ensure sustainable
          farming practices and fair trade standards, empowering agricultural
          communities while providing our clients with consistent, reliable, and
          top-quality products. With Tatva Overseas as your trusted supplier,
          you can be assured of receiving only the best pulses and beans that
          meet international quality standards, delivered promptly to your
          doorstep.
        </p>
      </div>
      <div>
        <>
          <div className="heading">
            <meta
              name="keywords"
              content="Indian Pulses, Pulses Exporters, Lentils, Chickpeas, Best Pulses Exporters"
            />
            <h1>Varieties of Pulses</h1>
          </div>
          <div className="underline"></div>
        </>
        <div className="searchcontainer">
          <input
            type="text"
            placeholder="Search varieties of Pulses..."
            value={searchQuery}
            onChange={handleSearch}
            className="searchinput"
          />
        </div>
        <div className="FruitsVeges_main">
          <div className="Fruits_main">
            {filteredPhotos.map((photo) => (
              <figure
                key={photo.id}
                className="gallery_item groceries_circular">
                <img
                  src={photo.url}
                  alt={photo.name}
                  className="Fruits_main_img"
                />
                <figcaption className="hover_text hover_text_grocery">
                  {photo.name}
                </figcaption>
              </figure>
            ))}
            {filteredPhotos.length === 0 && (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                No results found for "{searchQuery}".
              </p>
            )}
          </div>
        </div>
        {/* <div className="FruitsVeges_main">
          <div className="Fruits_main">
            <figure className="gallery_item groceries_circular">
              <img
                src={images.ToorDal}
                alt="ToorDal"
                className="Fruits_main_img"
              />
              <figcaption className="hover_text hover_text_grocery">
                Toor Dal (Pigeon Pea)
              </figcaption>
            </figure>
            <figure className="gallery_item groceries_circular">
              <img
                style={{ border: "5px solid", borderRadius: "50%" }}
                src={images.UradDal}
                alt="UradDal"
                className="Fruits_main_img"
              />
              <figcaption className="hover_text hover_text_grocery">
                Urad Dal (Black Gram)
              </figcaption>
            </figure>
            <figure className="gallery_item groceries_circular">
              <img
                src={images.MasoorDal}
                alt="MasoorDal"
                className="Fruits_main_img"
              />
              <figcaption className="hover_text hover_text_grocery">
                Masoor Dal (Red Lentil )
              </figcaption>
            </figure>
            <figure className="gallery_item groceries_circular">
              <img
                src={images.Chickpea}
                alt="Chickpea"
                className="Fruits_main_img"
              />
              <figcaption className="hover_text hover_text_grocery">
                Chickpea (Garbanzo / Chhola-Chhana)
              </figcaption>
            </figure>
            <figure className="gallery_item groceries_circular">
              <img
                src={images.Chanadal}
                alt="Chanadal"
                className="Fruits_main_img"
              />
              <figcaption className="hover_text hover_text_grocery">
                Chana Dal (Split chickpeas)
              </figcaption>
            </figure>
            <figure className="gallery_item groceries_circular">
              <img
                src={images.MothDal}
                alt="MothDal"
                className="Fruits_main_img"
              />
              <figcaption className="hover_text hover_text_grocery">
                Moth Dal <br />
                (Moth bean / Turkish gram)
              </figcaption>
            </figure>
            <figure className="gallery_item groceries_circular">
              <img src={images.Rajma} alt="Rajma" className="Fruits_main_img" />
              <figcaption className="hover_text hover_text_grocery">
                Rajma (Kidney Beans)
              </figcaption>
            </figure>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Groceries;
