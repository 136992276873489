import emailjs from "emailjs-com";
import { React, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import images from "../../constants/images";
import "./HomePriceInquiry.css";
 
const HomePriceInquiry = () => {
  const [formData, setFormData] = useState({
    name: "",
    productName: "",
    productQty: "",
    email: "",
    phone: "",
    // paymentTerms: "",
    port: "",
    website: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({ ...prev, phone: value }));
  };

// const handleCheckboxChange = (e) => {
//   const { value, checked } = e.target;
//   setFormData((prev) => {
//     if (checked) {
//       return { ...prev, delTerms: [...prev.delTerms, value] };
//     } else {
//       return {
//         ...prev,
//         delTerms: prev.delTerms.filter((term) => term !== value),
//       };
//     }
//   });
// };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_ibscjli", // Replace with your EmailJS service ID
        "template_vj6pw79", // Replace with your EmailJS template ID
        formData,
        "xJ-U8Tgv5LOCfsp4p" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.error("Error sending email:", error.text);
          alert("Failed to send the message. Please try again.");
        }
      );

    setFormData({
      name: "",
      productName: "",
      productQty: "",
      email: "",
      phone: "",
      port: "",
      website: "",
      description: "",
    });
  };

  return (
    <div
      className=""
      style={{
        backgroundPosition: "center",
        backgroundImage: `url(${images.bgImage})`,
      }}>
      <div className="form_main_top">
        <div className="form_main">
          <div className="values_head_main">
            <div className="heading">
              <h1>Get a Price Quote</h1>
            </div>
            <div className="underline"></div>
          </div>
          <form onSubmit={handleSubmit} className="quote_form_main">
            <div>
              <label className="field_main">
                Name *
                <input
                  placeholder="E.g. Rafael Nadal"
                  className="input_field input_transparent"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Product name / HSN code *
                <input
                  type="text"
                  name="productName"
                  value={formData.productName}
                  onChange={handleChange}
                  className="input_field input_transparent"
                  placeholder="E.g. Fresh Mangoes / 08045020"
                  required
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Product Quantity (Metric Ton) *
                <input
                  type="number"
                  name="productQty"
                  value={formData.productQty}
                  onChange={handleChange}
                  className="input_field input_transparent"
                  placeholder="E.g. 50 MT"
                  required
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Email *
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input_field input_transparent"
                  placeholder="E.g. name@gmail.com"
                  required
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Arival Port *
                <input
                  type="text"
                  name="port"
                  value={formData.port}
                  onChange={handleChange}
                  className="input_field input_transparent"
                  placeholder="E.g. Port of Jebel Ali"
                  required
                />
              </label>
            </div>
            <div className="field_main">
              <label>Contact *</label>
              <PhoneInput
                country={"us"}
                placeholder="E.g +91 97250 40440"
                style={{
                  padding: "0px",
                  paddingLeft: "0.6rem",
                  flexDirection: "row",
                }}
                name="phone"
                className="input_field"
                value={formData.phone}
                onChange={handlePhoneChange}
              />
            </div>
            {/* <div className="field_main">
              <legend>Delivery Terms *</legend>
              <div className="input_field">
                <label className="checkbox_inner">
                  <input
                    type="checkbox"
                    name="delTerms"
                    value="FOB"
                    checked={formData.delTerms.includes("FOB")}
                    onChange={handleCheckboxChange}
                  />
                  FOB
                </label>
                <label className="checkbox_inner">
                  <input
                    type="checkbox"
                    name="delTerms"
                    value="CIF"
                    checked={formData.delTerms.includes("CIF")}
                    onChange={handleCheckboxChange}
                  />
                  CIF
                </label>
              </div>
            </div> */}
            <div>
              <label className="field_main">
                Enter your website URL{" "}
                <input
                  name="website"
                  className="input_field input_transparent"
                  value={formData.website}
                  onChange={handleChange}
                  placeholder="E.g. tatvaOverseas.in"
                />
              </label>
            </div>
            <div>
              <label className="field_main">
                Description
                <textarea
                  name="description"
                  cols="30"
                  rows="6"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Detailed Description of the Product and Your Requirements"
                  className="input_field_message input_transparent"
                  style={{ width: "100%" }}
                  required></textarea>
              </label>
            </div>
            <div className="quote_btn_main">
              <button type="submit" className="quote_button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HomePriceInquiry;
