import ReactGA from "react-ga";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./common/footer/Footer";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Homepage from "./pages/homepage/Homepage";
import FruitsVeges from "./productsPages/fruitsVeges/FruitsVeges";
import Groceries from "./productsPages/groceries/Groceries";
import OilSeeds from "./productsPages/oilSeeds/OilSeeds";
import Spices from "./productsPages/spices/Spices";

function App() {
  const TRACKING_ID = "G-D667MLCC7Q";
  ReactGA.initialize(TRACKING_ID);
  return (
    <BrowserRouter>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Homepage />}></Route>{" "}
        <Route path="/Contact" element={<Contact />}></Route>{" "}
        <Route path="/About" element={<About />}></Route>{" "}
        <Route path="/FruitsVeges" element={<FruitsVeges />}></Route>{" "}
        <Route path="/Spices" element={<Spices />}></Route>{" "}
        <Route path="/Groceries" element={<Groceries />}></Route>{" "}
        <Route path="/OilSeeds" element={<OilSeeds />}></Route>{" "}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
