import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import NavbarSec from "../../common/navbarSec/NavbarSec";
import images from "../../constants/images";
import "./About.css";

const About = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation();
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | About</title>
      </Helmet>
      <NavbarSec />
      <div className="about_main">
        <div
          style={{
            backgroundImage: `url(${images.whyUs})`,
            backgroundPosition: "center",
            backdropFilter: "blur(w2px)",
            WebkitBackdropFilter: "revert-layer",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}>
          <div className="about_img_black_color">
            <div
              className="nav-main-nav-top"
              style={{
                backgroundColor: "transparent",
              }}>
              <div className="nav-main-nav">
                <nav className="Navbar_main">
                  <div
                    style={{
                      padding: "0.5rem 0.2rem",
                      border: "1px solid white",
                    }}>
                    <Link
                      to="/"
                      style={{
                        padding: "0.4rem 0",
                        fontSize: "2rem",
                        color: "white",
                        fontFamily: "Merriweather Sans",
                        border: "1px solid",
                        padding: "0.3rem 0.5rem",
                      }}>
                      Tatva Overseas
                    </Link>
                  </div>
                  <div className="navbar_links_main">
                    <div className="">
                      <Link
                        to="/"
                        className="link_decor_nav nav_link_h3  nav_link_h3_about"
                        style={{
                          color:
                            location.pathname === "/"
                              ? "var(--color-white)"
                              : "var(--color-black)",
                        }}>
                        Home
                      </Link>
                    </div>
                    <div className="">
                      <Link
                        to="/About"
                        className="link_decor_nav nav_link_h3 nav_link_h3_about"
                        style={{
                          color:
                            location.pathname === "/About"
                              ? "var(--color-white)"
                              : "var(--color-black)",
                        }}>
                        About
                      </Link>
                    </div>
                    <div
                      className="dropdown"
                      onMouseEnter={() => setShowProductsDropdown(true)}
                      onMouseLeave={() => setShowProductsDropdown(false)}>
                      <Link className="link_decor_nav nav_link_h3">
                        Products
                      </Link>
                      {showProductsDropdown && (
                        <div className="dropdown-content">
                          <Link to="/FruitsVeges">Fruits & Vegetables</Link>
                          <hr />
                          <Link to="/Spices">Spices</Link> <hr />
                          <Link to="/Groceries">Grocery Groceries</Link>
                          <Link to="/OilSeeds">OilSeeds</Link>
                        </div>
                      )}
                    </div>

                    <div className="">
                      <Link
                        to="/Contact"
                        className="link_decor_nav nav_link_h3  nav_link_h3_about"
                        style={{
                          color:
                            location.pathname === "/Contact"
                              ? "var(--color-white)"
                              : "var(--color-black)",
                        }}>
                        Contact
                      </Link>
                    </div>
                  </div>
                  <div className="nav-btn-icon-part">
                    {toggleMenu === false ? (
                      <button
                        className="nav-btn"
                        onClick={() => setToggleMenu(true)}>
                        <AiOutlineMenu className="nav-btn-icon" />
                      </button>
                    ) : (
                      <button
                        className="nav-btn"
                        onClick={() => setToggleMenu(false)}>
                        <AiOutlineClose className="nav-btn-icon" />
                      </button>
                    )}
                  </div>
                </nav>
              </div>
              <>
                {toggleMenu && (
                  <div
                    className="mobile-nav-main"
                    style={{ background: "var(--color-green-main)" }}>
                    <ul className="mobile-nav-links">
                      <li>
                        <Link
                          to={"/"}
                          onClick={() => setToggleMenu(false)}
                          className="mobile-nav-link">
                          Home
                        </Link>
                      </li>
                      <div className="dropdown">
                        <Link to="/About" className="mobile-nav-link">
                          About
                          <i className="fa fa-caret-down"></i>
                        </Link>
                      </div>
                      <div
                        className="dropdown"
                        onMouseEnter={() => setShowProductsDropdown(true)}
                        onMouseLeave={() => setShowProductsDropdown(false)}>
                        <Link className="link_decor_nav nav_link_h3">
                          Products
                        </Link>
                        {showProductsDropdown && (
                          <div className="dropdown-content">
                            <Link to="/FruitsVeges">Fruits & Vegetables</Link>
                            <hr />
                            <Link to="/Spices">Spices</Link> <hr />
                            <Link to="/Groceries">Grocery Groceries</Link>
                            <Link to="/OilSeeds">OilSeeds</Link>
                          </div>
                        )}
                      </div>

                      <li>
                        <Link to={"/Contact"} className="mobile-nav-link">
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            </div>
            <div className="about_img_con">
              <p
                className="about_img_con_txt"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  paddingLeft: "2rem",
                }}>
                It's Time To
                <br />
                KNOW US
                <div className="underline" style={{ margin: "0px" }}></div>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="about_content_main">
        <div>
          <div className="heading" style={{ margin: "0", paddingTop: "2rem" }}>
            <h1 className="">About Us </h1>
          </div>
          <div className="underline" style={{ marginBottom: "0.5rem" }}></div>
        </div>
        <div className="about_content_con">
          <div className="about_content_con_para">
            <p className="about_content_text">
              At Tatva Overseas, the company was founded with the vision of
              treating our customers like family, helping them become leaders in
              their markets by offering products with the best balance of price
              and quality. Our goal isn't to grow through undefined profit
              margins but to thrive alongside our satisfied customers by keeping
              our margins reasonable and focused on mutual success.
            </p>
            <br />
            <p className="about_content_text">
              We specialize in sourcing and exporting the freshest fruits and
              vegetables, spices, and grocery items from India to markets around
              the globe. Our commitment to quality ensures that every product
              meets high standards of freshness and safety, providing our
              clients with farm-fresh produce sourced from trusted suppliers.
              With a focus on reliability and customer satisfaction, we bridge
              the gap between India’s rich agricultural bounty and international
              demand, delivering the best of nature’s harvest.
            </p>
          </div>
          <div className="about_content_con_para">
            <img
              src={images.shipment}
              alt=""
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      <div className="our_miss_main" style={{ background: "whitesmoke" }}>
        <div className="values_head_main">
          <div className="heading">
            <h1>OUR MISSION & VISION</h1>
          </div>
          <div className="underline"></div>
        </div>
        <div className="our_miss_con">
          <div className="our_miss_part">
            <div className="our_miss_part_inner_first">
              <div className="our_miss_part_inner_bgwhite">
                <img
                  src={images.mission_icon}
                  alt=""
                  className="mission_icon"
                />
              </div>
            </div>
            <p className="our_miss_text">
              We want to reach to redefine industry standards through continuous
              innovation and expansion into new segments. Committed to providing
              sustainable solutions, our mission is to be the forefront of
              quality, ensuring that our products play a pivotal role in
              enhancing the efficiency & eco-friendliness of our clients'
              operations.
            </p>
          </div>
          <div className="our_miss_part2">
            <div className="our_miss_part_inner_sec">
              <div className="our_miss_part_inner_bgwhite">
                <img src={images.vision_icon} alt="" className="mission_icon" />
              </div>
            </div>

            <p className="our_miss_text">
              To be the global benchmark in manufacturing, delivering world -
              class products to delighted customers. Our vision is fueled by
              continuous improvement, driven by the integrity, teamwork, and
              creativity of our people.
            </p>
          </div>
        </div>
      </div>
      {/*  */}
      {/* <div className="about_certificate_main">
        <div className="values_head_main">
          <div className="heading">
            <h1>CERTIFICATES</h1>
          </div>
          <div className="underline"></div>
        </div>
        <div className="about_certificate_con">
          <img
            src={images.about_certificate}
            alt=""
            className="about_certificate_img"
          />
        </div>
      </div> */}
      {/*  */}
    </>
  );
};

export default About;
