import React from "react";
import "./HomeWhyUS.css";
import images from "../../constants/images";

const HomeWhyUS = () => {
  return (
    <div className="whyusHome_main">
      <div
        className=""
        style={{
          backgroundImage: `url(${images.whyUs})`,
          backgroundSize: "cover",
        }}>
        <div
          className=""
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "var(--color-white)",
            width: "100%",
            // height: "75vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <div style={{paddingTop:"4rem"}}>
            <h1 style={{ textAlign: "center" }}>WHY CHOOSE US</h1>
            <div className="underline"></div>
          </div>
          <div className="whyusHome_content_main">
            <div className="whyusHome_card_main">
              <div>
                <img
                  src={images.quality}
                  alt=""
                  className="whyusHome_img_inner"
                />
              </div>
              <h2>Trusted Quality Standards</h2>
              <p>
                We guarantee premium quality across all our products,
                meticulously sourced and inspected to meet international
                standards. Our rigorous quality control processes ensure you
                receive only the best for your customers.
              </p>
            </div>
            <div className="whyusHome_card_main">
              <div>
                <img
                  src={images.TrustedPartnership}
                  alt=""
                  className="whyusHome_img_inner"
                />
              </div>
              <h2>Trusted Partnerships</h2>
              <p>
                We work closely with local farmers and suppliers, building
                strong relationships that ensure reliable and consistent
                sourcing. This collaborative approach empowers communities and
                enhances the quality of our offerings.
              </p>
            </div>
            <div className="whyusHome_card_main">
              <div>
                <img
                  src={images.Logistics}
                  alt=""
                  className="whyusHome_img_inner"
                />
              </div>
              <h2>Efficient Logistics</h2>
              <p>
                With streamlined logistics and experienced teams, we ensure
                timely and safe delivery of goods, regardless of the
                destination. Our proactive communication keeps you informed
                every step of the way, ensuring a smooth experience.
              </p>
            </div>
            <div className="whyusHome_card_main">
              <div>
                <img
                  src={images.GlobalReach}
                  alt=""
                  className="whyusHome_img_inner"
                />
              </div>
              <h2>Global Reach</h2>
              <p>
                Our extensive network allows us to serve clients worldwide,
                connecting fresh and quality products with markets across the
                globe. No matter where you are, we make it easy to access the
                best products from diverse sources.
              </p>
            </div>
            <div className="whyusHome_card_main">
              <div>
                <img
                  src={images.customer_centric}
                  alt=""
                  className="whyusHome_img_inner"
                />
              </div>
              <h2>Customer-Centric Approach</h2>
              <p>
                We prioritize client satisfaction, providing personalized
                service and support at every stage to meet your unique business
                needs. Your success is our priority, and we adapt our services
                to fit your specific requirements.
              </p>
            </div>
            <div className="whyusHome_card_main">
              <div>
                <img
                  src={images.Relationship}
                  alt=""
                  className="whyusHome_img_inner"
                />
              </div>
              <h2>We Build Relationships</h2>
              <p>
                {" "}
                We prioritize fostering strong partnerships with our clients,
                suppliers, and local communities. By focusing on collaboration
                and open communication, we create lasting connections that drive
                mutual success and growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWhyUS;
