import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import images from "../../constants/images";
import "./FruitsVeges.css";

const FruitsVeges = () => {
  const photos = [
    { id: 1, name: "Mango", url: images.mango },
    { id: 2, name: "Banana", url: images.banana },
    { id: 3, name: "Pomegranate", url: images.pomegranate },
    { id: 4, name: "Sapodilla (Chikoo)", url: images.Sapodilla },
    { id: 5, name: "Grapes", url: images.Grapes },
    { id: 6, name: "Husked / Semi Husked Coconut", url: images.HuskedCoconut },
    { id: 7, name: "Lemon", url: images.Lemon },
    { id: 8, name: "Musk Melon", url: images.MuskMelon },
    { id: 9, name: "Onion", url: images.Onion },
    { id: 10, name: "Okra", url: images.Okra },
    { id: 11, name: "Drumstick (Moringa oleifera)", url: images.Drumstick },
    { id: 12, name: "Potato", url: images.Potato },
    { id: 13, name: "Ginger", url: images.Ginger },
    { id: 14, name: "Tomato", url: images.Tomato },
    { id: 15, name: "Elephant Yam", url: images.ElephantYam },
    { id: 16, name: "Peas", url: images.Peas },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPhotos, setFilteredPhotos] = useState(photos);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = photos.filter((photo) =>
      photo.name.toLowerCase().includes(query)
    );
    setFilteredPhotos(filtered);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | Fruits & Vegetables</title>
      </Helmet>
      <div style={{ paddingBottom: "0rem" }}>
        <Navbar />
      </div>
      <div className="pro_head_main">Fruits & Vegetables</div>
      <div className="pro_main_para">
        <p
          className="about_content_text"
          style={{
            margin: "0 auto",
            maxWidth: "1140px",
            marginTop: "3rem",
            marginBottom: "1rem",
            letterSpacing: "0.5px",
            wordSpacing: "0.5px",
          }}>
          We specialize in exporting high-quality, farm-fresh fruits and
          vegetables to global markets. We work directly with trusted local
          farmers to ensure that each product meets rigorous standards of
          freshness, hygiene, and quality. Through efficient logistics and
          careful handling, we deliver produce that retains its natural taste
          and nutritional value, bringing the best harvest to your business.
        </p>
        <p
          className="about_content_text"
          style={{
            margin: "0 auto",
            maxWidth: "1140px",
            marginBottom: "2rem",
            letterSpacing: "0.5px",
            wordSpacing: "0.5px",
          }}>
          Our export business focuses on bridging India's rich agricultural
          produce with the global demand for exotic, high-quality fruits and
          vegetables. By blending traditional farming wisdom with modern
          technology, we maintain the distinct flavors and textures that make
          our products unique. Every shipment is meticulously handled and
          quality-assured, ensuring our customers receive fresh produce ready to
          enhance their culinary creations. We are committed to cultivating a
          reputation for reliability and freshness, making our business a
          trusted source for premium exports worldwide.
        </p>
      </div>
      <div className="searchcontainer">
        <input
          type="text"
          placeholder="Search fruits & vegetables..."
          value={searchQuery}
          onChange={handleSearch}
          className="searchinput"
        />
      </div>
      <div className="FruitsVeges_main">
        <div className="Fruits_main">
          {filteredPhotos.map((photo) => (
            <figure key={photo.id} className="gallery_item">
              <img
                src={photo.url}
                alt={photo.name}
                className="Fruits_main_img"
              />
              <figcaption className="hover_text">{photo.name}</figcaption>
            </figure>
          ))}
          {filteredPhotos.length === 0 && (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              No results found for "{searchQuery}".
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default FruitsVeges;
