  import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import NavbarSec from "../../common/navbarSec/NavbarSec";
import {
  HeroSlider,
  HomeCoreValues,
  HomeHero,
  HomePriceInquiry,
  HomeProducts,
  HomeWhyUS
} from "../../components";
import "./Homepage.css";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas</title>
      </Helmet>
      <NavbarSec />
      <Navbar />
      <div>
        {/* <HomeHero /> */}
        <HeroSlider />
        <HomeProducts />
        <HomeCoreValues />
        <HomePriceInquiry />
        <HomeWhyUS />
      </div>
    </>
  );
};

export default Homepage;
