import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import images from "../../constants/images";
import "./HeroSlider.css";
const HeroSlider = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <div>
      <Slider {...settings} className="HeroSlider_main">
        <div>
          <div
            className="pro_sunflower"
            style={{
              backgroundImage: `url(${images.fruitsVeges})`,
              backgroundPosition: "inherit",
              backdropFilter: "blur(2px)",
              backgroundSize: "cover",
              width: "100%",
              height: "85vh",
              display: "flex",
              alignItems: "center",
            }}>
            <div
              style={{
                background:
                  "-webkit-gradient(linear,left top,right top,from(#000),color-stop(80%, rgba(0, 0, 0, 0.387)))",
                width: "100%",
                height: "100%",
              }}>
              <div className="hero_content_main">
                <div className="product_heading">
                  <h1 style={{ fontSize: "3rem" }}>
                    Freshness Beyond Borders.
                  </h1>
                </div>
                <div>
                  <h2 className="product_title">
                    We connect India's finest produce to tables across the
                    globe, combining quality, reliability, and care in every
                    shipment.
                  </h2>
                </div>
                <div>
                  <Link to="/FruitsVeges">
                    <button className="button_light">View more</button>
                  </Link>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="pro_sunflower"
            style={{
              backgroundImage: `url(${images.spices_hero})`,
              backgroundPosition: "center",
              width: "100%",
              height: "85vh",
              display: "flex",
              alignItems: "center",
            }}>
            <div
              style={{
                background:
                  "-webkit-gradient(linear,left top,right top,from(#000),color-stop(80%, rgba(0, 0, 0, 0.387)))",
                width: "100%",
                height: "100%",
              }}>
              <div className="hero_content_main">
                <div className="product_heading">
                  <h1 style={{ fontSize: "3rem" }}>
                    Spices That Enrich the World.
                  </h1>
                </div>
                <div>
                  <h2 className="product_title">
                    Bringing the essence of Indian flavors to global kitchens
                    with premium-quality, authentic spices.
                  </h2>
                </div>
                <div>
                  <Link to="/Spices">
                    <button className="button_light">View more</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div>
          <div
            className="pro_sunflower"
            style={{
              backgroundImage: `url(${images.Grocery})`,
              backgroundPosition: "inherit",
              backdropFilter: "blur(2px)",
              backgroundSize: "cover",
              width: "100vw",
              height: "85vh",
              display: "flex",
              alignItems: "center",
            }}>
            <div
              style={{
                background:
                  "-webkit-gradient(linear, 0% -10%, 170% 0%, from(rgb(0, 0, 0)), color-stop(0.8, rgba(0, 0, 0, 0.39)))",
                width: "100%",
                height: "100%",
              }}>
              <div className="hero_content_main">
                <div className="product_heading">
                  <h2>
                    Wholesome Groceries, <br />
                    Delivered Worldwide{" "}
                  </h2>
                </div>
                <div>
                  <h2 className="product_title">
                    Discover a wide range of premium Indian groceries, carefully
                    sourced to bring the taste of home to every corner of the
                    world.
                  </h2>
                </div>
                <div>
                  <Link to="/Groceries">
                    <button className="button_light">View more</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HeroSlider;
