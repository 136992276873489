import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import images from "../../constants/images";
import "./OilSeeds.css";

const OilSeeds = () => {
  const photos = [
    { id: 1, name: "Raw Peanut", url: images.RawPeanut },
    { id: 2, name: "Peanut in Shell", url: images.PeanutInShell },
    { i1d: 3, name: "Blanched Peanuts", url: images.BlanchedPeanuts },
    {
      id: 4,
      name: "Natural Sesame Seeds",
      url: images.NaturalSesameSeeds,
    },
    { id: 5, name: "Black Sesame Seed", url: images.BlackSesameSeed },
    { id: 6, name: "Hulled Sesame Seeds", url: images.HulledSesameSeeds },
    { id: 7, name: "Roasted Sesame Seed", url: images.RoastedSesameSeed },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPhotos, setFilteredPhotos] = useState(photos);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = photos.filter((photo) =>
      photo.name.toLowerCase().includes(query)
    );
    setFilteredPhotos(filtered);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | OilSeeds</title>
      </Helmet>
      <div style={{ paddingBottom: "0rem" }}>
        <Navbar />
      </div>
      <meta
        name="keywords"
        content="Indian oil seeds, Oil seeds Exporters, Best Oil seeds Exporters"
      />
      <div className="pro_head_main">Varieties of Oil Seeds</div>
      <div className="pro_main_para">
        <p
          className="about_content_text"
          style={{
            margin: "0 auto",
            maxWidth: "1140px",
            marginTop: "3rem",
            marginBottom: "1rem",
            letterSpacing: "0.5px",
            wordSpacing: "0.5px",
          }}>
          Tatva Overseas, exporters of oil seeds from India, specializes in
          delivering premium-quality agricultural products to global markets.
          Our diverse product range includes <strong>Raw Peanuts</strong>,{" "}
          <strong>Peanuts in Shell</strong>, <strong>Blanched Peanuts</strong>,{" "}
          <strong>Natural Sesame Seeds</strong>,{" "}
          <strong>Black Sesame Seeds</strong>,{" "}
          <strong>Hulled Sesame Seeds</strong>, and{" "}
          <strong>Roasted Sesame Seeds</strong>. With a commitment to excellence
          and customer satisfaction, we ensure meticulous sourcing, stringent
          quality checks, and efficient logistics to meet international
          standards. At Tatva Overseas, we take pride in fostering trust and
          long-lasting partnerships by providing the finest oil seeds to fulfill
          the needs of our esteemed clients worldwide.
        </p>
      </div>
      <div>
        <div className="searchcontainer">
          <input
            type="text"
            placeholder="Search varieties of Oil Seeds..."
            value={searchQuery}
            onChange={handleSearch}
            className="searchinput"
          />
        </div>
        <div className="FruitsVeges_main">
          <div className="Fruits_main">
            {filteredPhotos.map((photo) => (
              <figure
                key={photo.id}
                className="gallery_item groceries_circular">
                <img
                  src={photo.url}
                  alt={photo.name}
                  className="Fruits_main_img"
                />
                <figcaption className="hover_text hover_text_grocery">
                  {photo.name}
                </figcaption>
              </figure>
            ))}
            {filteredPhotos.length === 0 && (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                No results found for "{searchQuery}".
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OilSeeds;
