import React, { useState } from "react";
import images from "../../constants/images";
import "./HomeCoreValues.css";

const HomeCoreValues = () => {
  const photos = [
    {
      id: 1,
      url: images.qualityAssurance,
      name: "Quality Assurance",
      desc: "Committed to delivering only the freshest and highest quality produce that meets international standards, ensuring our clients receive the best products available.",
    },
    {
      id: 2,
      url: images.Sustainability_icon,
      name: "Sustainability",
      desc: "Supporting eco-friendly and sustainable practices to preserve resources and reduce our environmental impact, contributing to a healthier planet for future generations.",
    },
    {
      id: 3,
      url: images.transparency,
      name: "Transparency",
      desc: " Maintaining honesty and openness in all operations, from sourcing to shipping, so clients always know what they're getting, fostering trust and loyalty in our partnerships.",
    },
    {
      id: 4,
      url: images.Customer_icon,
      name: "Customer Satisfaction",
      desc: "Our customers are at the heart of everything we do. We are dedicated to understanding and exceeding their expectations, ensuring their satisfaction with our quality and reliable service.",
    },
    {
      id: 5,
      url: images.community,
      name: "Community Support",
      desc: "Building strong partnerships with local farmers, supporting agricultural communities and fair trade practices, empowering them to thrive in a competitive market.",
    },
    {
      id: 6,
      url: images.Integrity_icon,
      name: "Integrity",
      desc: "Integrity is the foundation of our business. We operate with honesty, transparency, and a commitment to ethical practices in all our interactions.",
    },
  ];
  const [filteredPhotos, setFilteredPhotos] = useState(photos);

  return (
    <div className="values_main_top">
      <div className="values_head_main">
        <div className="heading">
          <h1>CORE VALUES</h1>
        </div>
        <div className="underline"></div>
      </div>
        <div className="values_main">
        {filteredPhotos.map((photo) => (
          <div className="values_boxes">
            <img
              src={photo.url}
              className="values_img_inner"
              alt={photo.name}
            />
              <div className="">
              <h3 className="heading_values">{photo.name}</h3>
              </div>
              <div className="values_para">
                <p>{photo.desc}
                </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeCoreValues;
