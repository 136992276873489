/* eslint-disable jsx-a11y/iframe-has-title */
import emailjs from "emailjs-com";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../common/navbar/Navbar";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_ibscjli", // Replace with your EmailJS service ID
        "template_atnjfpn", // Replace with your EmailJS template ID
        formData,
        "xJ-U8Tgv5LOCfsp4p" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.error("Error sending email:", error.text);
          alert("Failed to send the message. Please try again.");
        }
      );

    setFormData({ name: "", phone: "", email: "", message: "" });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatva Overseas | Contact</title>
      </Helmet>
      <Navbar />
      <div className="ContactUsHome_main">
        <div className="pro_head_main">CONTACT US</div>

        <div className="heading_sec_contact_main">
          {/* <div>
            <h1 className="contactustitle">CONTACT US</h1>
            <div className="underline"></div>
          </div> */}

          <div>
            <h2 style={{ textAlign: "center" }}>
              Reach out to us – we're here to help your business thrive!
            </h2>
          </div>
        </div>
        <div className="form_sec_main_home">
          <div className="contactUs_Keep_in_touch_main">
            <h1>Keep in touch</h1>
            <div>
              <p>
                We run a business grounded in trust, which we build through
                clear communication and dependable support. From your first
                interaction with us and through each anniversary, we're here for
                you every step of the way.
              </p>
            </div>
            <div>
              <p>
                We look forward to discussing your business needs and answering
                any questions you may have. Just enter your details, and we'll
                get back to you shortly. Our team undergoes regular specialized
                training to ensure you receive the best information possible.
                Whether it’s a simple question or a complex compliance inquiry,
                we’re here to help.
              </p>
            </div>
          </div>

          <form className="form_sec_main_home_inner" onSubmit={handleSubmit}>
            <div className="form_sec_main_home_inner_part">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="input_field"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Contact no."
                className="input_field"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_sec_main_home_inner_part">
              <input
                type="email"
                name="email"
                placeholder="Email id."
                className="input_field"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <textarea
                name="message"
                rows="9"
                cols="50"
                placeholder="Write your message here"
                className="input_field_message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <button
                className="button_dark"
                type="submit"
                style={{ padding: "0.7rem", width: "100%" }}>
                Submit
              </button>
            </div>
          </form>
        </div>
        {/* <div className="map_and_contact_main">
          <div className="contactdetails_home_main">
            <div className="contactdetails_home1">
              <h4>Contact no : </h4>
              <Link to="tel:+91 9725040440" className="details_p_contact">
                +91 97250 40440
              </Link>
            </div>

            <div className="contactdetails_home1">
              <h3>Office Address : </h3>
              <Link
                to="https://maps.app.goo.gl/91V1ZtqiYg7KDBPE7"
                target="_blank">
                <p className="details_p_contact" style={{ maxWidth: "380px" }}>
                  "54,Shiv Aradhna soc., B/H Alap Heritage,
                  <br /> Satya sai road, Rajkot-360005"
                </p>
              </Link>
            </div>
            <div className="contactdetails_home1">
              <h3>Email : </h3>
              <Link
                to="mailto:tatvaOverseas@gmail.com"
                className="details_p_contact">
                tatvaOverseas@gmail.com
              </Link>
            </div>
            <div className="socialicons_contact_home_main">
              <FaFacebook className="socialicons_contact_home_inner" />
              <FaInstagram className="socialicons_contact_home_inner" />
              <FaTwitter className="socialicons_contact_home_inner" />
              <FaWhatsapp className="socialicons_contact_home_inner" />
            </div>
          </div>
          <div className="ContactUsCards_mapblock">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d7384.100222223812!2d70.76583344075944!3d22.276091532585806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1732689611410!5m2!1sen!2sin"
              width="400px"
              height="324"
              frameborder=""
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"></iframe>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Contact;
